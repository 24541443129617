import React from "react"
import { Layout } from "../components"
import EventForm from "../forms/EventForm"

const Events = () => {
  return (
    <Layout>
      <div className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center mb-8">
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Special Events &amp; Venue Rental
            </h3>
          </div>

          <div className="mt-10 sm:mt-0">
            <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-600 text-white">
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 448 512"
                        stroke="currentColor"
                      >
                        <path
                          fill="currentColor"
                          d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">
                      Corporate Events
                    </h4>
                    <article className="prose mt-2 text-base leading-6 text-gray-500">
                      <p>
                        Wow your team and host a kick axe corporate event that
                        everyone will be talking about! Ready to plan your next
                        corporate axe throwing event?
                      </p>
                      <p>
                        “bury all hatchets” here at Big’s you will be coming out
                        better and stronger with boosted employee and workplace
                        morale! Big’s offers a great way to integrate new staff
                        members, stimulate existing employees, and inspire
                        higher functioning teamwork. Choose teams, get
                        competitive and hit the targets!
                      </p>
                      <p>
                        During your axe throwing event you will have a dedicated
                        coach to go over safety and rules and teach you proper
                        throwing techniques and guide you from start to finish.
                      </p>
                      <p>
                        Treat your staff with food and drinks! We have a variety
                        of beer to offer and our friends at The Mill have tons
                        of delicious food, or simply bring in your own
                        food/beverages (non-alcoholic)
                      </p>
                      <h4>
                        Message us to get prices for facility rental and more..
                      </h4>
                    </article>
                  </div>
                </div>
              </li>
              <li id="birthday-parties" className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-600 text-white">
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 640 512"
                        stroke="currentColor"
                      >
                        <path
                          fill="currentColor"
                          d="M240.6 194.1c1.9-30.8 17.3-61.2 44-79.8C279.4 103.5 268.7 96 256 96h-29.4l30.7-22c7.2-5.1 8.9-15.1 3.7-22.3l-9.3-13c-5.1-7.2-15.1-8.9-22.3-3.7l-32 22.9 11.5-30.6c3.1-8.3-1.1-17.5-9.4-20.6l-15-5.6c-8.3-3.1-17.5 1.1-20.6 9.4l-19.9 53-19.9-53.1C121 2.1 111.8-2.1 103.5 1l-15 5.6C80.2 9.7 76 19 79.2 27.2l11.5 30.6L58.6 35c-7.2-5.1-17.2-3.5-22.3 3.7l-9.3 13c-5.1 7.2-3.5 17.2 3.7 22.3l30.7 22H32c-17.7 0-32 14.3-32 32v352c0 17.7 14.3 32 32 32h168.9c-5.5-9.5-8.9-20.3-8.9-32V256c0-29.9 20.8-55 48.6-61.9zM224 480c0 17.7 14.3 32 32 32h160V384H224v96zm224 32h160c17.7 0 32-14.3 32-32v-96H448v128zm160-288h-20.4c2.6-7.6 4.4-15.5 4.4-23.8 0-35.5-27-72.2-72.1-72.2-48.1 0-75.9 47.7-87.9 75.3-12.1-27.6-39.9-75.3-87.9-75.3-45.1 0-72.1 36.7-72.1 72.2 0 8.3 1.7 16.2 4.4 23.8H256c-17.7 0-32 14.3-32 32v96h192V224h15.3l.7-.2.7.2H448v128h192v-96c0-17.7-14.3-32-32-32zm-272 0c-2.7-1.4-5.1-3-7.2-4.8-7.3-6.4-8.8-13.8-8.8-19 0-9.7 6.4-24.2 24.1-24.2 18.7 0 35.6 27.4 44.5 48H336zm199.2-4.8c-2.1 1.8-4.5 3.4-7.2 4.8h-52.6c8.8-20.3 25.8-48 44.5-48 17.7 0 24.1 14.5 24.1 24.2 0 5.2-1.5 12.6-8.8 19z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">
                      Birthday Parties
                    </h4>
                    <article className="mt-2 text-base leading-6 text-gray-500">
                      <p>
                        Looking for a kick axe birthday party with family and
                        friends? Well look no further Big’s Axe here in Dalton
                        has you covered! An axe-cellent throwing party will
                        excite your friends and you will defintetly be the talk
                        of the town for years to come.
                      </p>
                      <div id="party-package" className="prose">
                        <h5>Party Package Includes:</h5>
                        <ul>
                          <li className="text-sm">
                            Dedicated coach on side to train and guide your
                            throwing experience.
                          </li>
                          <li className="text-sm">1 hour of axe throwing</li>
                          <li className="text-sm">
                            Personal Music Selection available
                          </li>
                          <li className="text-sm">
                            Special gift for the birthday person
                          </li>
                          <li className="text-sm">Facility closed to public</li>
                          <li className="text-sm">
                            Access to all lanes and axes
                          </li>
                          <li className="text-sm">
                            May bring in your own food/beverages (non-alcoholic)
                          </li>
                        </ul>
                      </div>
                      <p>Ready for a Bullseye of a Party?</p>
                    </article>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-600 text-white">
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 576 512"
                        stroke="currentColor"
                      >
                        <path
                          fill="currentColor"
                          d="M564 0h-79c-10.7 0-16 12.9-8.5 20.5l16.9 16.9-48.7 48.7C422.5 72.1 396.2 64 368 64c-33.7 0-64.6 11.6-89.2 30.9 14 16.7 25 36 32.1 57.1 14.5-14.8 34.7-24 57.1-24 44.1 0 80 35.9 80 80s-35.9 80-80 80c-22.3 0-42.6-9.2-57.1-24-7.1 21.1-18 40.4-32.1 57.1 24.5 19.4 55.5 30.9 89.2 30.9 79.5 0 144-64.5 144-144 0-28.2-8.1-54.5-22.1-76.7l48.7-48.7 16.9 16.9c2.4 2.4 5.4 3.5 8.4 3.5 6.2 0 12.1-4.8 12.1-12V12c0-6.6-5.4-12-12-12zM144 64C64.5 64 0 128.5 0 208c0 68.5 47.9 125.9 112 140.4V400H76c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h36v36c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-36h36c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-36v-51.6c64.1-14.6 112-71.9 112-140.4 0-79.5-64.5-144-144-144zm0 224c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">
                      Gender Reveal Parties
                    </h4>
                    <article className="prose mt-2 text-base leading-6 text-gray-500">
                      <p>
                        Boy or Girl? Now days everyone is throwing parties to
                        know their baby gender, so why not be a future bad axe
                        parent and be unique and throw an axe at a balloon to
                        find out your gender?
                      </p>

                      <p>
                        Come on in and we will provide 3 balloons, one of them
                        will have your baby's gender.
                      </p>

                      <p>
                        We promise you and guest will have a great time, nothing
                        like celebrating your baby's gender.
                      </p>
                    </article>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-600 text-white">
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 512 512"
                        stroke="currentColor"
                      >
                        <path
                          fill="currentColor"
                          d="M351.25 160.77A206.38 206.38 0 01379.9 233 112 112 0 11224 336c0-42.21 23.69-78.57 58.31-97.49 3.37 10.64 5.69 21.75 5.69 33.49a111.34 111.34 0 01-30.73 76.6A79.84 79.84 0 00293 403.23 175.36 175.36 0 00352 272c0-81.62-55.64-150.07-131-170l35-70-32-32h-96L96 32l35 70C55.64 121.93 0 190.38 0 272a176 176 0 00176 176 171.77 171.77 0 0022.94-1.71A175.93 175.93 0 00512 336c0-92-70.7-167.49-160.75-175.23zM64 272a112.12 112.12 0 01112-112c26.85 0 51.19 9.88 70.5 25.69C194.94 216.24 160 271.68 160 336a175.89 175.89 0 006.55 47C109.28 378.16 64 330.52 64 272z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">
                      Bachelor &amp; Bachelorette Parties
                    </h4>
                    <article className="prose mt-2 text-base leading-6 text-gray-500">
                      <p>
                        The date is set and You’ve made the biggest decision of
                        your life! Now you are looking for an unforgettable way
                        to celebrate your last days being single! Your guests
                        and you will have an axepert at your side to guide and
                        teach you through throwing an axe! Have a guest that
                        does not want to throw but still wants to party? That’s
                        okay! We have seating areas by all ranges for guests to
                        spectate and cheer on their friends…at no charge
                      </p>
                    </article>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <EventForm />
      </div>
    </Layout>
  )
}

export default Events
